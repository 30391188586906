<template>
  <div class="container">
    <div class="content">
      杭州隅星互联网科技有限责任公司（后面以“我们”代称）承诺皮可米APP（简称“本APP”）严格遵守法律法规，遵循以下隐私保护原则，为您提供更加安全、可靠的服务：
    </div>
    <div class="content">
      我们的产品基于DCloud uni-app(5+
      App/Wap2App)开发，应用运行期间需要收集您的设备唯一识别码（IMEI/android
      ID/DEVICE_ID/IDFA、SIM 卡 IMSI
      信息）以提供统计分析服务，并通过应用启动数据及异常错误日志分析改进性能和用户体验，为用户提供更好的服务。详情内容请访问《DCloud用户服务条款》。（DCloud用户服务条款超链至：<a
        href="https://ask.dcloud.net.cn/protocol.html"
        >https://ask.dcloud.net.cn/protocol.html</a
      >）
    </div>
    <div class="content">
      《皮可米隐私政策》适用于皮可米提供的软件、网站、服务，包括但不限于适用电脑、移动智能终端。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《皮可米隐私政策》向您说明，在使用我们的服务时我们收集哪些信息，如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《皮可米隐私政策》与您所使用的皮可米服务息息相关，希望您仔细阅读。在需要时，按照本《皮可米隐私政策》的指引，做出您认为适当的选择。
    </div>
    <div class="content">
      您开始访问或使用我们的服务，即意味着同意我们按照本《皮可米隐私政策》收集、使用、储存和分享您的相关信息
    </div>
    <div class="content">本隐私政策将帮助您了解以下内容：</div>
    <div class="content">1. 我们如何收集信息</div>
    <div class="content">2. 我们如何使用您的个人信息</div>
    <div class="content">3. 我们如何使用 Cookie 及相关技术</div>
    <div class="content">4. 我们如何共享、转让、公开披露您的个人信息</div>
    <div class="content">5. 您对个人信息的权利</div>
    <div class="content">6. 我们如何保护您的个人信息</div>
    <div class="content">7. 存储信息的地点和期限</div>
    <div class="content">8. 我们如何处理未成年人的个人信息</div>
    <div class="content">9. 隐私政策的修订</div>
    <div class="content">10. 如何联系我们</div>
    <div class="title big">1. 我们如何收集信息</div>
    <div class="content">
      我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。
      您在使用我们服务时主动提供的信息。
    </div>
    <div class="title">1.1 您在注册账号时填写的信息</div>
    <div class="content">例如，您在注册账号时所填写手机号码、姓名等。</div>
    <div class="title">1.2 您在使用服务时上传的信息</div>
    <div class="content">
      您使用我们的特定产品和服务时，为满足向您提供产品和服务之目的，除注册时提供的信息外，您可能还需要进一步向我们提供与上述产品和服务的功能相关的信息（例如您的真实姓名、性别、身份证号码、地址、银行卡、头像和简介等）。
    </div>
    <div class="title">1.3 我们在您使用服务时获取的信息</div>
    <div class="title">1.3.1日志信息</div>
    <div class="content">
      当您使用我们的服务时，我们可能会自动收集相关信息并存储为服务日志信息。
    </div>
    <div class="content">1) 设备信息</div>
    <div class="content">
      您使用产品或服务时使用的设备的属性、链接、状态信息。例如，设备型号、操作系统版本、唯一设备ID（将用于标识您为皮可米达人版的用户）、电信运营商、使用的语言、硬件型号、设备配置、国际移动设备身份码IMEI、网络设备硬件地址MAC、广告标识符IDFA、设备传感器数据，设备应用安装列表等。
    </div>
    <div class="content">2) 软件信息</div>
    <div class="content">
      为确保操作环境的安全或提供服务所需，我们会收集有关您使用的移动应用和其他软件的信息。例如，软件的版本号、浏览器类型等。
    </div>
    <div class="content">3) IP地址</div>
    <div class="content">4) 服务日志信息</div>
    <div class="content">
      例如，您在使用我们的服务时，搜索、查看的信息、服务故障信息、引荐网址等。
    </div>
    <div class="title">1.3.2位置信息</div>
    <div class="content">
      当您使用与位置有关的服务时，我们可能会记录您设备所在的位置信息，以便为您提供相关服务。
    </div>
    <div class="content">
      在您使用服务时，我们可能会通过IP地址
      、GPS、WLAN（如 WiFi）或基站等途径获取您的地理位置信息(我们仅是收集您当时所处的地理位置，但不会将您各时间段的位置信息进行结合分析您的行踪轨迹）。行踪轨迹为个人敏感信息，除具体产品或服务功能所需外，我们的产品或服务不会主动收集该等个人敏感信息。您可以通过关闭定位功能，停止对您的地理位置信息的收集，但您可能将无法获得相关服务或功能，或者无法达到相关服务拟达到的效果。
    </div>
    <div class="content">
      您或其他用户在使用服务时，提供的信息中可能包含您所在地理位置信息，例如您提供的账号信息中可能包含的您所在地区信息，您或其他人共享的照片包含的地理标记信息。
    </div>
    <div class="title">1.3.3其他相关信息</div>
    <div class="content">其他用户分享的信息中含有您的信息</div>
    <div class="content">
      例如，其他用户发布的照片或文字中可能包含您的信息。
    </div>
    <div class="title">1.4 设备权限调用</div>
    <div class="content">
      您在产品使用过程中，可能会需要您的一些设备授权。以下是可能调用的权限及对应的业务功能、目的及调用前向您询问的情况。
      您可以在设备的设置功能中选择关闭部分或全部权限，但可能会影响到部分功能使用。在不同设备中，权限显示方式及关闭方式可能有所不同，具体请参考设备及系统开发方说明或指引。
    </div>
    <div class="table t1">
      <div class="table-head">
        <div class="table-head-td">设备权限</div>
        <div class="table-head-td">对应业务功能</div>
        <div class="table-head-td">调用权限的目的</div>
        <div class="table-head-td">是否询问</div>
        <div class="table-head-td">是否可关闭权限</div>
      </div>
      <div class="table-body">
        <div class="table-tr">
          <div class="table-td">麦克风/录音</div>
          <div class="table-td">录制视频及录制音频功能</div>
          <div class="table-td">收听用户的指令</div>
          <div class="table-td">
            用户在使用该功能的时候.弹窗询问，如录制视频，录制音频等
          </div>
          <div class="table-td">是</div>
        </div>
        <div class="table-tr">
          <div class="table-td">相册</div>
          <div class="table-td">上传视频功能</div>
          <div class="table-td">获取用户照片库，提供快速找到之前照片的能力</div>
          <div class="table-td">在上述功能用户选择“从相册上传”时</div>
          <div class="table-td">是</div>
        </div>
        <div class="table-tr">
          <div class="table-td">相机</div>
          <div class="table-td">拍摄视频功能</div>
          <div class="table-td">打开摄像头进行录像，拍照的能力</div>
          <div class="table-td">在上述功能用户选择拍照</div>
          <div class="table-td">是</div>
        </div>
        <div class="table-tr">
          <div class="table-td">位置</div>
          <div class="table-td">消息推送</div>
          <div class="table-td">根据用户位置进行活动推送</div>
          <div class="table-td">
            否，仅用户主动去 设置-应用-权限管理-皮可米达人版-权限管理
            中开启定位权限才有效，默认不获取定位
          </div>
          <div class="table-td">是</div>
        </div>
        <div class="table-tr">
          <div class="table-td">通知</div>
          <div class="table-td">消息推送</div>
          <div class="table-td">发送消息</div>
          <div class="table-td">首次打开客户端时询问</div>
          <div class="table-td">是</div>
        </div>
      </div>
    </div>
    <div class="content">
      为了向您提供更好的服务，我们的产品可能会更新和变化，当我们可能将您的隐私信息用于本隐私政策未载明的其它用途时，我们会事先征求您的明示同意，例如：通过界面提示、邮件等方式向您详细说明信息收集的目的、方式、范围，并征求您的同意。若您不同意提供前述信息，您可能无法使用特定的产品或服务。
    </div>
    <div class="title big">2. 我们如何使用您的信息</div>
    <div class="title">2.1 向您提供服务；</div>
    <div class="title">
      2.2 有时我们也会请您提供更多的信息。我们这样做是为了使我们更好的理解您的需求，以便向您提供有效的服务；
    </div>
    <div class="title">
      2.3 我们可能在必需时（例如当我们由于系统维护而暂停某一项服务时）发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告；
    </div>
    <div class="title">
      2.4 我们使用收集的信息来提供并改进我们及关联方、合作方提供的产品和服务，并进行必要的业务运营，例如运营产品或提供服务，评估、维护和改进产品和服务的性能，开发新的产品和服务、提供客户支持等。我们也可能会对产品使用情况进行统计和分析。同时，可能会与公众共享这些统计信息，以展示我们服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。
    </div>
    <div class="title">
      2.5 我们会将所收集到的信息用于大数据分析。例如，我们将收集到的信息用于分析形成不包含任何个人信息的统计类产品。我们可能对外公开并与我们的合作伙伴分享经统计加工后不含身份识别内容的大数据分析信息。
    </div>
    <div class="title">2.6 让您参与有关我们产品和服务的调查；</div>
    <div class="title">
      2.7 与您直接沟通，我们会利用收集的信息（例如您的手机号码、电子邮件地址）直接与您沟通或互动。例如，如果我们或我们的关联公司检测到可疑活动（例如尝试从不同于平常的位置登录您的帐号）或违法违纪或有其他侵权行为时，可能会向您发送通知。
    </div>
    <div class="title">
      2.8 为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
    </div>
    <div class="title big">3. 我们如何使用cookie及相关技术,</div>
    <div class="content">
      我们或我们的第三方合作伙伴，可能通过放置安全的Cookie及相关技术收集您的信息，目的是为您提供更个性化的用户体验和服务，并用于以下用途：
    </div>
    <div class="content">3.1 存储您向我们提供的有关您的喜好或其他信息；</div>
    <div class="content">
      3.2 分析您使用我们服务的情况，用于了解您的偏好，改善产品服务和用户体验
    </div>
    <div class="title big">4. 我们如何共享、转让、公开披露您的个人信息,</div>
    <div class="title">
      4.1 共享为了更好的给您提供服务，我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理分享，具体说明如下：
    </div>
    <div class="content">
      1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
    </div>
    <div class="content">
      2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
    </div>
    <div class="content">
      3、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，我们聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。在这种情况下，这些公司必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。
    </div>
    <div class="content">
      4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
    </div>
    <div class="content">5、关于我们可能接入的合作伙伴等第三方的SDK目录</div>
    <div class="table t2" style="margin-bottom: 20rpx">
      <div class="table-head">
        <div class="table-head-td">SDK名称</div>
        <div class="table-head-td">SDK包名</div>
        <div class="table-head-td">SDK用途</div>
        <div class="table-head-td">可能获取的个人信息类型</div>
        <div class="table-head-td">调用的设备权限</div>
        <div class="table-head-td">官网链接/隐私政策链接</div>
      </div>
      <div class="table-body">
        <div class="table-tr">
          <div class="table-td">阿里weexSDK</div>
          <div class="table-td">com.taobao</div>
          <div class="table-td">
            uni-app基础模块默认集成，用于渲染uniapp的nvue页面引擎
          </div>
          <div class="table-td">存储的个人文件</div>
          <div class="table-td">读取外置存储器、写入外置存储器</div>
          <div class="table-td">http://doc.weex.io/zh</div>
        </div>
        <div class="table-tr">
          <div class="table-td">fresco图片库</div>
          <div class="table-td">com.facebook.fresco</div>
          <div class="table-td">
            uni-app基础模块默认集成，用于nvue页面加载图片使用
          </div>
          <div class="table-td">存储的个人文件</div>
          <div class="table-td">读取外置存储器、写入外置存储器</div>
          <div class="table-td">https://www.fresco-cn.org/</div>
        </div>
        <div class="table-tr">
          <div class="table-td">个推</div>
          <div class="table-td">com.getui.gtc 、com.igexin.sdk</div>
          <div class="table-td">UniPush推送</div>
          <div class="table-td">网络信息、IMEI、openid</div>
          <div class="table-td">
            获取网络状态、访问Wi-Fi状态、读取手机状态和身份
          </div>
          <div class="table-td">https://docs.getui.com/privacy/</div>
        </div>
        <div class="table-tr">
          <div class="table-td">友盟</div>
          <div class="table-td">com.uc.crashsdk、com.efs、com.umeng</div>
          <div class="table-td">统计</div>
          <div class="table-td">网络信息、IMEI、openid</div>
          <div class="table-td">
            获取网络状态、访问Wi-Fi状态、读取手机状态和身份
          </div>
          <div class="table-td">https://www.umeng.com/page/policy</div>
        </div>
        <div class="table-tr">
          <div class="table-td">微信</div>
          <div class="table-td">com.tencent.mm</div>
          <div class="table-td">微信分享</div>
          <div class="table-td">存储的个人文件</div>
          <div class="table-td">读取外置存储器、写入外置存储器</div>
          <div class="table-td">
            https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">高德</div>
          <div class="table-td">com.amap.api, com.loc, com.autonavi</div>
          <div class="table-td">地图&定位</div>
          <div class="table-td">IMEI、openid、位置信息</div>
          <div class="table-td">
            获取网络状态、访问Wi-Fi状态、位置信息、访问粗略位置、访问精准定位、读取手机状态和身份
          </div>
          <div class="table-td">https://lbs.amap.com/agreement/compliance</div>
        </div>
      </div>
    </div>
    <div class="content">
      如我们或我们的关联公司与任何上述第三方分享您的个人信息，我们将确保该第三方在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施。
    </div>
    <div class="title">4.2 转让</div>
    <div class="content">
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </div>
    <div class="content">
      1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
    </div>
    <div class="content">
      2、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
    </div>
    <div class="title">4.3 公开披露</div>
    <div class="content">
      根据相关法律法规及国家标准，在以下情形中，我们可能会依法收集并使用您的个人信息并且无需征得您的同意:
    </div>
    <div class="content">1、与国家安全、国防安全直接相关的；</div>
    <div class="content">2、与公共安全、公共卫生、重大公共利益直接相关的；</div>
    <div class="content">3、与犯罪侦查、起诉、审判和判决执行等直接相关的；</div>
    <div class="content">
      4、出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；
    </div>
    <div class="content">5、所收集的个人信息是您自行向社会公众公开的；</div>
    <div class="content">
      6、从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；
    </div>
    <div class="content">7、根据您的要求签订和履行合同所必需的</div>
    <div class="content">
      8、用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置产品或服务的故障；
    </div>
    <div class="content">9、为合法的新闻报道所必需的；</div>
    <div class="content">
      10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
    </div>
    <div class="content">11、法律法规规定的其他情形。</div>
    <div class="title big">5. 您对个人信息的权利</div>
    <div class="content">
      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
    </div>
    <div class="title">5.1 访问您的个人信息</div>
    <div class="content">
      您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：
    </div>
    <div class="content">
      1）账号信息——如果您希望访问或编辑您的账号中的个人资料信息，包括：头像、姓名、简介等。
    </div>
    <div class="content">
      2）使用信息——您可以通过相关产品页面随时查阅您的使用信息，包括：收藏记录、浏览历史、离线下载记录、订单信息、积分信息等。
    </div>
    <div class="title">5.2 更正您的个人信息</div>
    <div class="content">
      您可以在我们的产品与或服务中更正、修改您的相关个人信息。为便于您行使您的上述权利，我们为您提供了在线自行更正、修改和向我们提出更正申请两种方式。
    </div>
    <div class="content">
      对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作指引和操作设置，您可以直接进行更正、修改；您还可以通过“联系我们”中的联系方式通知皮可米，以帮助我们保持您的资料的准确性。
    </div>
    <div class="title">5.3 删除您的个人信息</div>
    <div class="content">
      一般而言，我们只会在法律法规规定或必需且最短的时间内保存您的个人信息。为便于您行使您的上述删除权，我们为您提供了在线自行删除和向我们提出删除申请两种方式。
    </div>
    <div class="content">
      对于您的部分个人信息，我们在产品的相关功能页面为您提供了操作设置，您可以直接进行删除。一旦您删除后，我们即会对此类信息进行删除或匿名化处理，除非法律法规另有规定。
    </div>
    <div class="title">在以下情形中，您可以向我们提出删除个人信息的请求：</div>
    <div class="content">1）如果我们处理个人信息的行为违反法律法规；</div>
    <div class="content">
      2）如果我们收集、使用您的个人信息，却未征得您的同意；
    </div>
    <div class="content">3）如果我们处理个人信息的行为违反了与您的约定；</div>
    <div class="content">
      4）如果您不再使用我们的产品或服务，或您注销了账号；
    </div>
    <div class="content">5）如果我们不再为您提供产品或服务。</div>
    <div class="content">
      您理解并同意：当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    </div>
    <div class="title">5.4 改变您授权同意的范围或撤回您的授权</div>
    <div class="content">
      每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。您可以通过您的硬件设备修改个人设置、或在我们的产品或服务中的相关功能设置界面进行操作处理。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。
    </div>
    <div class="content">
      当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </div>
    <div class="title">5.5 响应您的上述请求</div>
    <div class="content">
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    </div>
    <div class="content">
      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
    </div>
    <div class="title">
      在以下情形中，按照法律法规要求，我们将无法响应您的请求：
    </div>
    <div class="content">1）与国家安全、国防安全有关的；</div>
    <div class="content">2）与公共安全、公共卫生、重大公共利益有关的；</div>
    <div class="content">3）与犯罪侦查、起诉和审判等有关的；</div>
    <div class="content">4）有充分证据表明您存在主观恶意或滥用权利的；</div>
    <div class="content">
      5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
    </div>
    <div class="title big">6. 我们如何保护您的个人信息</div>
    <div class="title">
      6.1我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据时受 SSL 加密保护；我们同时对我们网站提供 https 安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
    </div>
    <div class="title">
      6.2 我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
    </div>
    <div class="title">
      6.3 互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
    </div>
    <div class="title">
      6.4 互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
    </div>
    <div class="title">
      6.5 在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们会及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
    </div>
    <div class="title big">7. 存储信息的地点和期限</div>
    <div class="title">7.1存储信息的地点</div>
    <div class="content">
      我们遵守法律法规的规定，将境内收集的用户个人信息存储于中华人民共和国境内。
    </div>
    <div class="title">7.2 存储信息的期限</div>
    <div class="content">
      一般而言，我们仅为实现目的所必需的最短时间保留您的个人信息，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，根据适用法律的要求,我们会可能删除您的个人信息。但在下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：
    </div>
    <div class="content">1）为遵守适用的法律法规等有关规定；</div>
    <div class="content">2）为遵守法院判决、裁定或其他法律程序的规定；</div>
    <div class="content">3）为遵守相关政府机关或法定授权组织的要求；</div>
    <div class="content">4）我们有理由确信需要遵守法律法规等有关规定；</div>
    <div class="content">
      为执行相关服务协议或本政策、维护社会公共利益，为保护们的客户、我们或我们的关联公司、其他用户或雇员的人身财产安全或其他合法权益所合理必需的用途。
    </div>
    <div class="content">
      当我们的产品或服务发生停止运营的情形时，我们将采取例如，推送通知、公告等形式通知您，并在合理的期限内删除或匿名化处理您的个人信息。
    </div>
    <div class="title big">8. 我们如何处理未成年的个人信息</div>
    <div class="content">
      我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，若您是18周岁以下的未成年人，在使用皮可米的服务前，应事先取得您的家长或法定监护人的同意。
    </div>
    <div class="content">
      若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过“如何联系我们”章节中的联系方式与我们联系。
    </div>
    <div class="content">
      如果我们发现自己在未事先获得可证实的父母或法定监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。
    </div>
    <div class="title big">9. 隐私政策的修订</div>
    <div class="content">
      为了给您提供更好的服务，我们会根据产品的更新情况及法律法规的相关要求随时修改本《皮可米隐私政策》的条款。如该等修改造成您在本《皮可米隐私政策》下权利的实质减少或重大变更，您可以选择停止使用我们向您提供的服务；在该种情况下，若您仍然继续使用我们的服务，即表示同意受经修订的本《皮可米隐私政策》的约束。
    </div>
    <div class="content">上述的“重大变更”包括但不限于：</div>
    <div class="content">
      1）我们的服务模式发生重大变化。如处理个人信息的目的、处理个人信息的类型、个人信息的使用方式等；
    </div>
    <div class="content">
      2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </div>
    <div class="content">
      3）个人信息共享、转让或公开披露的主要对象发生变化；
    </div>
    <div class="content">
      4）您参与个人信息处理方面的权利及其行使方式发生重大变化；
    </div>
    <div class="content">
      5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </div>
    <div class="content">6）个人信息安全影响评估报告表明存在高风险时；</div>
    <div class="content">
      7）其他重要的或可能严重影响您的个人权益的情况发生时。任何修改都会将您的满意度置于首位。我们鼓励您在每次访问皮可米时都查阅我们的隐私政策。
    </div>
    <div class="title big">10. 如何联系我们</div>
    <div class="content">
      如您对本《皮可米隐私政策》的执行或使用我们的服务时遇到的与隐私保护相关的事宜有任何问题（包括问题咨询、投诉等），我们专门为您提供了多种反馈通道，希望为您提供满意的解决方案：
    </div>
    <div class="content">登录APP，通过“联系我们”进行解决。</div>
    <div class="content">
      我们会在收到您的意见及建议，并在验证您用户身份后尽快向您回复，一般情况下，我们不会因此对您收取服务费。但是，在以下情形下，您理解并知悉，我们将无法响应您的请求：
    </div>
    <div class="content">1）与国家安全、国防安全有关的；</div>
    <div class="content">2）与公共安全、公共卫生、重大公共利益有关的；</div>
    <div class="content">3）与犯罪侦查、起诉和审判等有关的；</div>
    <div class="content">4）有充分证据表明您存在主观恶意或滥用权利的；</div>
    <div class="content">
      5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
    </div>
    <div class="content">6）涉及商业秘密的；</div>
    <div class="content">7）法律法规等规定的其他情形。</div>
    <div class="content right"> 本声明自更新之日起生效 </div>
    <div class="content right"> 最近的更新日期：2021年10月19日 </div>
    <div class="content right" style="padding-bottom:30px;"> 杭州隅星互联网科技有限责任公司 </div>
  </div>
</template>
<script>
export default {
  mounted(){
    window.scrollTo(0, 0)
  }
};
</script>
<style scoped>
.container {
  padding: 0 30px;
}
.title {
  font-weight: 600;
  padding:8px 0;
}
.content{
    margin-bottom:5px;
}
.right{
    text-align: right;
    margin-top:15px;
}
.big{
  font-size:24px;
  margin-top:15px;
}
.table {
  width: 100%;
  margin-top: 20px;
}
.table-head {
  width: 100%;
  background: #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
}
.t1 .table-head-td,
.t1 .table-td {
  width: 25%;
}
.t2 .table-head-td,
.t2 .table-td {
  width: 15%;
}
.table-head-td,
.table-td {
  font-size: 14px;
  text-align: center;
  word-break: break-all;
}
.table-body {
  width: 100%;
}
.table-tr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  border: 1rpx solid #aaa;
}
</style>